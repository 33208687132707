import React from 'react';
import TextField from '@material-ui/core/TextField';
import server from '../functions/server';
import userId from '../functions/userid';
import $ from 'jquery';
import Title from '../product/add_product/title';
import { Button, Checkbox, Grid, GridColumn, Header, Segment } from 'semantic-ui-react';

export default class ShopSettings extends React.Component {

  state = {

  };

  componentDidMount() {
    let context = this;
    let shopId = context.props.data.id;
    let url = server() + '/shopsettings/get.php';
    let data = { shop_id: shopId };
    $.ajax({
      url, data, type: 'POST',
    }).done((data) => {
      try {
        data = JSON.parse(data);
        let billHeader = data[0].bill_header > 0 ? 1 : 0;
        let billFooter = data[0].bill_footer > 0 ? 1 : 0;
        let billDue = data[0].bill_due > 0 ? 1 : 0;
        context.setState({ billHeader, billFooter, billDue })
      } catch (error) {

      }
    });
  }


  submit = () => {
    let context = this;
    let shopId = context.props.data.id;
    let { billHeader, billFooter, billDue } = this.state;
    let url = server() + '/shopsettings/add.php';
    let data = {
      shop_id: shopId,
      bill_header: billHeader ? 1 : 0,
      bill_footer: billFooter ? 1 : 0,
      bill_due: billDue ? 1 : 0,
    };
    $.ajax({
      url, data, type: 'POST',
    }).done((data) => {
      if (data === 1 || data === '1') {
        alert("Success");
      }
      else {
        alert('Failed to save');
      }
    });
  }

  render() {
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id}
          title={this.props.data.shop_name + " - Shop Settings"} menu={true} />

        <div style={{ margin: '1%' }}>
          <Header as='h3' attached='top'>
            Hide A4 Bill Content
          </Header>
          <Segment attached>
            <Grid style={{ marginLeft: '2%' }}>
              <GridColumn mobile={16} tablet={4} computer={3}>
                <Checkbox fluid
                  label="Bill Header"
                  checked={this.state.billHeader}
                  onChange={(e, { checked }) => this.setState({ billHeader: checked })} />
              </GridColumn>
              <GridColumn mobile={16} tablet={4} computer={3}>
                <Checkbox fluid
                  label="Bill Footer"
                  checked={this.state.billFooter}
                  onChange={(e, { checked }) => this.setState({ billFooter: checked })} />
              </GridColumn>
              <GridColumn mobile={16} tablet={4} computer={3}>
                <Checkbox fluid
                  label="Bill Due"
                  checked={this.state.billDue}
                  onChange={(e, { checked }) => this.setState({ billDue: checked })}
                />
              </GridColumn>
              <GridColumn mobile={16} tablet={4} computer={3}>
                <Button primary fluid onClick={this.submit}>Save</Button>
              </GridColumn>
            </Grid>
          </Segment>
        </div>
      </div >
    );
  }
}
