
import React from "react";
import { Card, CardHeader, CardBody, CardFooter, CardTitle, FormGroup, Form, Input, Row, Col } from "reactstrap";
import QRCode from 'qrcode.react';
import { Link } from "react-router-dom";
import {Icon, Button, Image} from 'semantic-ui-react';
import { Grid } from 'semantic-ui-react';
import Typography from '@material-ui/core/Typography';
import $ from 'jquery';

import 'semantic-ui-css/semantic.min.css'
import AddImage from '../../media/addimage.jpg';
import LineChart from './linechart';
import userId from '../../functions/userid';
import server from '../../functions/server';
import AddIcon from '../../media/addicon.png';
import EditProfile from './editprofile';

class User extends React.Component {


  handleFile = name => () => document.getElementById(name).click()

  handleFileChange = name => e => {
    this.updateLocal(name, e.target.files[0]);
    this.updateDB(name, e.target.files[0]);
  }

  updateLocal(type, value) {
    let reader  = new FileReader();
    let context = this;
    reader.addEventListener("load", function () {
      context.props.data[type] = reader.result;
      context.setState({
        [type]: value
      });
    }, false);
    if (value) {
      reader.readAsDataURL(value);
    }
  }

  updateDB(type, file) {
    if (!type || !userId() || !this.props.data.id || !file) {
      console.log("err");
      return;
    }
    let context = this;
    var formData = new FormData();
    formData.append("owner", userId());
    formData.append("id", this.props.data.id);
    formData.append("type", type);
    formData.append("file", file);
    $.ajax({
      type: 'POST',
      url: server() + "/shop/updateimage.php", data: formData,
      processData: false, contentType: false,
      beforeSend: function(x) {
          context.setState({
            dimmer: true,
            loadText: "Updating " + type
          });
      },
      success: function(data, status) {
        console.log(data);
        context.setState({
          dimmer: false,
          loadText: ""
        });
      },
      error: function(data, status) {
        console.log(data);
        context.setState({
          dimmer: false,
          loadText: ""
        });
      }
    });
  }

  viewShoppingSite() {
    let data = this.props.data;
    let shopName = data.shop_name;
    shopName = shopName.replaceAll(" ", "-");
    window.open("https://shop.strackit.com/"+shopName);
  }



  render() {
    let data = this.props.data;
    let icon = (this.props.data.icon) ? this.props.data.icon : AddIcon;
    if (!data) {
      return (<div></div>);
    }
    let feature_image = (this.props.data.feature_image) ? this.props.data.feature_image : AddImage;
    return (
        <div style={{marginTop: '3%'}}>
          <Grid style={{marginLeft: '2%', marginRight: '2%'}}>
            <Grid.Column mobile={16} tablet={16} computer={10}>
                <Grid>
                  <Grid.Column mobile={8} tablet={8} computer={4}>
                    <Link to="/billing" style={{textDecoration: 'none'}}>
                      <Card style={{width: '100%', cursor: 'pointer'}}>
                        <CardHeader>
                          <CardTitle tag="h4">Orders</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <ul className="list-unstyled team-members">
                              <Row>
                                <Col md="8" xs="8"><h4>{data.ordercounts}</h4></Col>
                                <Col className="text-right" md="3" xs="3">
                                  <Button circular color='twitter' icon='add' />
                                </Col>
                              </Row>
                          </ul>
                        </CardBody>
                      </Card>
                    </Link>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={4}>
                    <Link to="/purchases" style={{textDecoration: 'none'}}>
                      <Card style={{width: '100%', cursor: 'pointer'}}>
                        <CardHeader>
                          <CardTitle tag="h4">Purchases</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <ul className="list-unstyled team-members">
                              <Row>
                                <Col md="8" xs="8"><h4>{data.purchasecounts}</h4></Col>
                                <Col className="text-right" md="3" xs="3">
                                  <Button circular color='twitter' icon='add' />
                                </Col>
                              </Row>
                          </ul>
                        </CardBody>
                      </Card>
                    </Link>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={4}>
                    <Link to="/stocks" style={{textDecoration: 'none'}}>
                      <Card style={{width: '100%', cursor: 'pointer'}}>
                        <CardHeader>
                          <CardTitle tag="h4">Stocks</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <ul className="list-unstyled team-members">
                              <Row>
                                <Col md="8" xs="8"><h4>{data.stockcounts}</h4></Col>
                                <Col md="2" xs="2">
                                  <Button circular color='twitter' icon='add' />
                                </Col>
                              </Row>
                          </ul>
                        </CardBody>
                      </Card>
                    </Link>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={4}>
                    <Link to="/customers" style={{textDecoration: 'none'}}>
                      <Card style={{width: '100%', cursor: 'pointer'}}>
                        <CardHeader>
                          <CardTitle tag="h4">Customers</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <ul className="list-unstyled team-members">
                              <Row>
                                <Col md="8" xs="8"><h4>{data.customercount}</h4></Col>
                                <Col className="text-right" md="3" xs="3">
                                  <Button circular color='twitter' icon='add' />
                                </Col>
                              </Row>
                          </ul>
                        </CardBody>
                      </Card>
                    </Link>
                  </Grid.Column>
                </Grid>
                <LineChart shopId={this.props.shopId} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Card className="card-user">
                <div className="image">
                  <Image label={{ as: 'a', color: 'teal', content: 'Feature Image',  ribbon: true }} src={feature_image} />
                </div>
                <CardBody>
                  <div className="author">
                    <center>

                      <input onChange={this.handleFileChange('icon')} id="icon" type="file" style={{display: 'none'}} />
                      <Image onClick={this.handleFile('icon')} circular src={icon} size='small' circular style={{width: '35%', cursor: 'pointer'}} />

                    </center>
                    <h5>{data.shop_name.toUpperCase()}</h5>
                    <p className="description" style={{marginTop: '2%', fontSize: 14}}>
                      {data.description}
                    </p>
                    <EditProfile id={data.id} data={data} />

                  </div>
                  <Row style={{marginTop: '5%'}}>
                    <Col md="7" xs="7">
                      <p style={{marginLeft: '3%'}}>
                       {data.address},<br />
                       {data.city+", "+data.state}<br />
                       {data.country+", "+data.pincode} <br />
                       {"Shop Id: " + data.id}
                    </p>
                    <Button fluid primary onClick={this.viewShoppingSite.bind(this)}>View My Shopping Site</Button>
                    </Col>
                    <Col className="text-right" md="4" xs="4">
                      <QRCode size={100} level={'H'} value={data.shop_name} />
                      </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="button-container">
                    <Row>
                      <Col className="ml-auto" lg="3" md="6" xs="6" style={{marginTop: '2%'}}>
                        <a href={data.facebook_link} target="_blank">
                          <Button circular color='facebook' icon='facebook' />
                        </a>
                      </Col>
                      <Col className="ml-auto mr-auto" lg="3" md="6" xs="6" style={{marginTop: '2%'}}>
                        <a href={data.twitter_link} target="_blank">
                          <Button circular color='twitter' icon='twitter' />
                        </a>
                      </Col>
                      <Col className="ml-auto mr-auto" lg="3" md="6" xs="6" style={{marginTop: '2%'}}>
                        <Button circular color='blue' icon='map marker alternate' />
                      </Col>
                      <Col className="ml-auto mr-auto" lg="3" md="6" xs="6" style={{marginTop: '2%'}}>
                        <a href={data.instagram_link} target="_blank">
                          <Button circular color='instagram' icon='instagram' />
                        </a>
                      </Col>
                    </Row>
                  </div>
                </CardFooter>
              </Card>

            </Grid.Column>
          </Grid>
          <center>
            <div onClick={ () => window.location.href = "https://business.strackit.com/StrackitBusinessBillingAPK.apk" }>
              <Image style={{ width: 250, height: 50, marginTop:'5%'}} src={require("../assets/img/apple.jpg")} />
            </div>
          </center>
        </div>
    );
  }
}
export default User;
